/* eslint-disable */
// import generateSign from '@/utils/sign'
import service from '../service'

async function pigai(input, clientInfo) {
  // // 发送后文本的\n会变成\n\t,导致加密和解析不一致而失败，所以替换为空格
  // const comcontext = input.replace(/\n/g, ' ')
  // const sign = generateSign(comcontext)
  const sign = '123'
  const baseUrl = '/translate'
  const from = input.from
  const to = input.to
  const q = input.q
  let params = {
    from,
    to,
    q,
    // keyid: '23',
    // sign,
  }
  if (clientInfo) {
    params = Object.assign(params, clientInfo)
  }
  return service.post(baseUrl, params)
}

export default {
  pigai,
}
