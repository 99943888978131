<template lang="pug">
.faq_page
  nav-bar
  language-bar(
    ref="languageBar"
    v-show="visible"
    :select="selectSide"
    @close="closePopUp"
    :origin="originLanguage"
    :target="targetLanguage"
  )
  .content_box
    .lang_bar
      .orgin_lang(
        @click="selectLanguage('origin')")
        | {{ $TT(originLanguage) }}
        img.arrow(src="@/assets/images/translate/arrow.png")
      .lang_icon(@click="interchangeLan")
        img.circle(src="@/assets/images/translate/circle.png")
      .target_lang(@click="selectLanguage('target')")
        | {{ $TT(targetLanguage) }}
        img.arrow(src="@/assets/images/translate/arrow.png")
    .translate_panel
      .input_panel
        textarea.input(
            :placeholder="$TT('Please enter text')"
            maxlength="10000"
            v-model="inputText"
            @blur="overInput")
        a.cleanbox(@click="cleanText")
          img.clean(v-if="clean_show" src="@/assets/images/translate/clean_text.png")
        a.soundbox(@click="getPronounce('input')")
          .alertBox(v-if="in_notSound")
              img.alert_img(src="@/assets/images/translate/alert.png")
              p.alert_text(v-if="true") {{$TT('Current language does not support pronunciation.')}}
          audio(:src="in_audioSrc", ref="in_audio", @ended="audioOver('input')")
          img.sound(v-if="in_show" src="@/assets/images/translate/sound_btn.png")
          img.sound_first(v-if="!in_show" src="@/assets/images/translate/sound_btn_first.png")
          img.sound_second(v-if="!in_show" src="@/assets/images/translate/sound_btn_second.png")
          img.sound_third(v-if="!in_show" src="@/assets/images/translate/sound_btn_third.png")
        button.submit(@click="translate") {{ btnName }}
      .output_panel
        textarea.output(readonly) {{ translateText }}
        a.soundbox(@click="getPronounce('output')")
          .alertBox(v-if="out_notSound")
              img.alert_img(src="@/assets/images/translate/alert.png")
              p.alert_text(v-if="true") {{$TT('Current language does not support pronunciation.')}}
          audio(:src="out_audioSrc", ref="out_audio", @ended="audioOver('output')")
          img.sound(v-if="out_show" src="@/assets/images/translate/sound_btn.png")
          img.sound_first(v-if="!out_show" src="@/assets/images/translate/sound_btn_first.png")
          img.sound_second(v-if="!out_show" src="@/assets/images/translate/sound_btn_second.png")
          img.sound_third(v-if="!out_show" src="@/assets/images/translate/sound_btn_third.png")
        a
          img.copy(src="@/assets/images/translate/copy_icon.png" @click="copy")
  dict-footer
</template>
<script>
import { fixedBody, looseBody, getPronounceUrl } from '@/utils/index'
import navBar from '@/components/pc/navBar.vue'
import dictFooter from '@/components/pc/dictFooter.vue'
import service from '@/services/trans/index'
import languageBar from './components/languageBar.vue'

export default {
  name: 'Translate',
  components: {
    navBar,
    dictFooter,
    languageBar,
  },
  data() {
    return {
      visible: false,
      in_show: true,
      out_show: true,
      clean_show: true,
      in_notSound: false,
      out_notSound: false,
      originLanguage: 'Any Language',
      originCode: 'auto',
      targetLanguage: 'English',
      targetCode: 'en',
      selectSide: '',
      inputText: '',
      translateText: '',
      btnName: 'Translate',
      prounouncebaseUrl: 'http://dict.youdao.com/dictvoice?audio=',
      in_audioSrc: '',
      out_audioSrc: '',
    }
  },
  watch: {
    '$store.state.selectLang': {
      handler() {
        this.btnName = this.$TT('Translate');
        this.inputText = ''
      },
      immediate: true,
    },
    inputText: {
      handler() {
        if (!this.inputText) {
          this.translateText = '';
          this.clean_show = false;
          this.in_audioSrc = '';
          this.out_audioSrc = '';
          this.out_show = true;
          this.in_show = true;
        } else {
          this.clean_show = true
        }
      },
      immediate: true,
    },
  },
  methods: {
    interchangeLan() {
      if (this.$TT(this.originLanguage) !== this.$TT('Any Language')) {
        const changeLan = this.originLanguage;
        const changeCode = this.originCode;
        this.originLanguage = this.targetLanguage;
        this.targetLanguage = changeLan;
        this.originCode = this.targetCode;
        this.targetCode = changeCode;
        this.in_audioSrc = this.inputText ? getPronounceUrl(this.inputText.substring(0, 200), this.originCode) : '';
        this.out_audioSrc = this.translateText ? getPronounceUrl(this.translateText.substring(0, 200), this.targetCode) : '';
        this.out_show = true;
        this.in_show = true;
      }
    },
    selectLanguage(side) {
      this.visible = true;
      this.selectSide = side;
      this.$refs.languageBar.clear();
      fixedBody();
    },
    closePopUp(data) {
      if (data) {
        if (data.select === 'origin') {
          this.originCode = data.code;
          this.originLanguage = data.language;
          this.in_audioSrc = this.inputText ? getPronounceUrl(this.inputText.substring(0, 200), this.originCode) : '';
        } else {
          this.targetCode = data.code;
          this.targetLanguage = data.language;
          this.out_audioSrc = this.translateText ? getPronounceUrl(this.translateText.substring(0, 200), this.targetCode) : '';
        }
      }
      if (!this.$refs.out_audio.paused && this.out_audioSrc) {
        this.$refs.out_audio.pause()
        this.out_show = true;
      }
      if (!this.$refs.in_audio.paused && this.in_audioSrc) {
        this.$refs.in_audio.pause()
        this.in_show = true;
      }
      this.visible = false;
      this.selectSide = '';
      looseBody();
    },
    translate() {
      const input = {
        from: this.originCode,
        to: this.targetCode,
        q: this.inputText,
      }
      service.pigai(input)
        .then((data) => {
          this.translateText = data.translate.tran;
          this.out_audioSrc = this.translateText ? getPronounceUrl(this.translateText.substring(0, 200), this.targetCode) : '';
        })
        .catch((err) => {
          console.log('error --> ', err);
        })
    },
    copy() {
      const el = document.querySelector('.output');
      el.select();
      document.execCommand('copy');
      document.execCommand('BackColor', false, '#FFFFFF')
    },
    overInput() {
      this.in_audioSrc = this.inputText ? getPronounceUrl(this.inputText.substring(0, 200), this.originCode) : '';
    },
    getPronounce(val) {
      if (val === 'input' && this.in_audioSrc) {
        if (!this.$refs.out_audio.paused && this.out_audioSrc) {
          this.$refs.out_audio.pause()
          this.out_show = true;
        }
        this.$refs.in_audio.currentTime = 0
        this.$refs.in_audio.play().then(() => {
          this.in_show = false;
        }).catch(() => {
          this.in_notSound = true;
          setTimeout(() => {
            this.in_notSound = false;
          }, 3000)
        })
      } else if (val === 'output' && this.out_audioSrc) {
        if (!this.$refs.in_audio.paused && this.in_audioSrc) {
          this.$refs.in_audio.pause()
          this.in_show = true;
        }
        this.$refs.out_audio.currentTime = 0
        this.$refs.out_audio.play().then(() => {
          this.out_show = false;
        }).catch(() => {
          this.out_notSound = true;
          setTimeout(() => {
            this.out_notSound = false;
          }, 3000)
        })
      }
    },
    audioOver(val) {
      if (val === 'input') {
        this.in_show = true;
      } else if (val === 'output') {
        this.out_show = true;
      }
    },
    cleanText() {
      this.inputText = '';
      if (!this.$refs.out_audio.paused && this.out_audioSrc) {
        this.$refs.out_audio.pause()
        this.out_show = true;
      }
      if (!this.$refs.in_audio.paused && this.in_audioSrc) {
        this.$refs.in_audio.pause()
        this.in_show = true;
      }
    },
  },
}
</script>

<style lang="stylus" scoped>
.faq_page
  position relative
  width 100%
  min-width 1300px
  background url('~@/assets/images/translate/background.png')
  .pop_up
    .moda
      position fixed
      left 0
      right 0
      top 0
      bottom 0
      opacity 0.6
      background #000000
      z-index 1000
    .languages
      position fixed
      width 454px
      height 644px
      background #FFFFFF
      z-index 1001
      top 50%
      left 50%
      transform translate(-227px, -322px)
      border-radius 19px
  .content_box
    padding-top 60px
    height 94vh
    .lang_bar
      width 600px
      height 46px
      margin 65px auto 0
      border-radius 19px
      display flex
      align-items center
      justify-content center
      color #061C32
      font-size 18px
      line-height 20px
      .orgin_lang
        margin-left 0
        width 49%
        text-align right
      .lang_icon
        margin-left 90px
        margin-right 90px
        width 2%
        .circle
          margin-top 4px
          width 14px
      .target_lang
        margin-left 0
        width 49%
        text-align left
  .translate_panel
    width 1200px
    height 484px
    margin 24px auto 0
    display flex
    .input_panel
      position relative
      border 1px solid #808080
      border-radius 20px 0 0 20px
      height 53vh
      width 600px
      background #FFFFFF
      .input
        margin 32px 0 60px 40px
        height 40vh
        width 490px
        border none
        outline none
        resize none
        font-family PingFang SC
        font-weight 500
        font-size 18px
        line-height 25px
        color #1e1e20
        letter-spacing 0.09px
        overflow auto
      .clean
        position absolute
        width 15px
        right 25px
        top 4.1vh
      .alertBox
        position absolute
        width 360px
        left 15px
        top 41vh
        .alert_img
          width 100%
        .alert_text
          position: absolute
          top: 7px
          left: 2%
          color red
      .sound
        position absolute
        left 40px
        top 46vh
      .sound_first
        position absolute
        width 33px
        left 40px
        top 46vh
      .sound_second
        position absolute
        width 33px
        left 40px
        top 46vh
        animation toSound 1s infinite 0.2s
      .sound_third
        position absolute
        width 33px
        left 40px
        top 46vh
        animation toSound 1s infinite 0.4s
      @keyframes toSound{
          0% {
             opacity 0
          }
          100% {
             opacity 1
          }
      }
      .word_limit
        position absolute
        line-height 33px
        left 94px
        top 454px
      .submit
        position absolute
        top 45vh
        left 403px
        width 134px
        height 40px
        background #FF592B
        border-radius 10px
        border none
        color #FFFFFF
        font-size 18px
        line-height 25px
    .output_panel
      position relative
      height 53vh
      width 600px
      border-radius 0 20px 20px 0
      border 1px solid #808080
      background #FFFFFF
      .output
        width 490px
        border-radius 0 20px 20px 0
        height 40vh
        margin 32px 0 60px 40px
        border none
        outline none
        resize none
        font-size 18px
        font-weight 500
        line-height 25px
        letter-spacing 0.09px
        color #1E1E20
        overflow auto
      .alertBox
        position absolute
        width 360px
        left 15px
        top 41vh
        .alert_img
           width 100%
        .alert_text
           position: absolute
           top: 7px
           left: 2%
           color red
      .sound
        position absolute
        width 33px
        top 46vh
        left 40px
      .sound_first
        position absolute
        width 33px
        left 40px
        top 46vh
      .sound_second
        position absolute
        width 33px
        left 40px
        top 46vh
        animation toSound 1s infinite 0.2s
      .sound_third
        position absolute
        width 33px
        left 40px
        top 46vh
        animation toSound 1s infinite 0.4s
      @keyframes toSound{
        0% {
            opacity 0
        }
        100% {
            opacity 1
        }
      }
      .copy
        position absolute
        width 16px
        top 46vh
        left 515px
.arrow
  width 8px
  margin-left 8px
  margin-bottom 2px
.fade-enter
  opacity 0
.fade-leave
  opacity 0.6
.fade-enter-active, .fade-leave-active
  transition opacity 0.3s
.fade-enter-to
  opacity 0.6
.fade-leve-to
  opacity 0
</style>
