<template lang="pug">
.language_bar
  .pop_up
    transition(name='fade')
      .moda(@click="closeWindow")
    .bar_content(:key="langSelect")
      .header
        .origin(:class="{ dark: select==='target'}")
          span.orgin_text {{$TT(origin)}}
          img.arrow(src="@/assets/images/translate/arrow.png")
        .icon
          .line
          img.circle(src="@/assets/images/translate/circle.png")
          .line
        .target(:class="{ dark: select==='origin'}")
          span.target_text {{$TT(target)}}
          img.arrow(src="@/assets/images/translate/arrow.png")
      .search_bar
        input.search(
          type='text'
          v-model="searchText"
          :placeholder='$TT("choose a language")'
          maxLength=20
          @input="handleChange" )
      .fuzzy_search(v-show="showHint")
        .item(
          v-for="(word, index) in suggestions"
          v-show="!isError"
          :key="index"
          @click="selectLanguage(word)"
        ) {{ $TT(word[0]) }}
        .error(v-show="isError")
          p Can't find this language, please check it and type it again!
      .choice_list
        .language(
          v-for="(item, index) in list"
          @click="selectLanguage(item)"
          :key="index")
          .touch_area
            img.tick(src="@/assets/images/translate/tick.png" :style="{ visibility: isVisible }")
            span.lang_text {{$TT(item[0])}}
</template>
<script>
import { languages } from '@/config/languages';
import { fuzzySearch } from '@/utils'
import { mapState } from 'vuex';

export default {
  name: 'languageBar',
  data() {
    return {
      list: [],
      isVisible: 'hidden',
      showHint: false,
      suggestions: [],
      searchText: '',
      searchData: '',
      isError: false,
    }
  },
  props: ['select', 'origin', 'target'],
  mounted() {
    this.list = languages.langList
  },
  computed: {
    ...mapState(['langSelect', 'selectLang']),
  },
  watch: {
    selectLang: {
      handler() {
        this.list[0][0] = this.$TT('Any Language')
      },
    },
  },
  methods: {
    closeWindow() {
      this.$emit('close');
    },
    selectLanguage(item) {
      if (this.select === 'origin' && this.$TT(item[0]) !== this.$TT(this.target)) {
        const data = {
          code: item[1],
          language: item[0],
          select: this.select,
        };
        this.$emit('close', data)
      }
      if (this.select === 'target' && this.$TT(item[0]) !== this.$TT(this.origin) && this.$TT(item[0]) !== this.$TT('Any Language')) {
        console.log(item[0])
        const data = {
          code: item[1],
          language: item[0],
          select: this.select,
        };
        this.$emit('close', data)
      }
    },
    /*
    * 是否使用搜索功能 --> 使用，则进行模糊搜索 --> 有返回结果，展示结* 果，无则显示查找错误
    */
    handleChange() {
      if (this.searchText.length !== 0) {
        this.showHint = true;
        this.searchData = this.list.filter((index) => this.$TT(index[0]));
        this.suggestions = fuzzySearch(this.searchData, this.searchText);
        if (this.suggestions.length === 0) {
          this.isError = true;
          setTimeout(() => {
            this.isError = false;
            this.showHint = false;
          }, 1000);
        }
      } else {
        this.showHint = false;
      }
    },
    handleSelect(word) {
      this.selectLanguage(word);
    },
    clear() {
      this.searchText = '';
      this.suggestions = [];
      this.showHint = false;
    },
  },
}
</script>
<style lang="stylus" scoped>
.language_bar
  .pop_up
    .moda
      position fixed
      left 0
      right 0
      top 0
      bottom 0
      opacity 0.6
      background #000000
      z-index 1000
    .bar_content
      position fixed
      width 500px
      height 644px
      background #FFFFFF
      z-index 1001
      top 50%
      left 50%
      transform translate(-227px, -322px)
      border-radius 19px
      .header
        position relative
        width 100%
        height 46px
        display flex
        align-items center
        border-bottom 1px solid #C4C8CC
        .origin
          height 20px
          margin 12px 0 0 40px
          .origin_text
            font-size 14px
            line-height 20px
            color #061C32
          .arrow
            width 9px
            margin-left 8px
        .icon
          display flex
          margin 10px 40px 0 40px
          .line
            width 1px
            background #E3E4E6
            opacity 0.34
          .circle
            width 14px
            margin 0 30px
            opacity 0.1
        .target
          height 20px
          margin-top 10px
          .target_text
            font-size 14px
            line-height 20px
            color #061C32
          .arrow
            width 9px
            margin-left 8px
      .search_bar
        padding  8px 0
        border-bottom 1px solid #C4C8CC
        .search
          width 200px
          height 16px
          margin-left 40px
          outline none
          border none
          &::placeholder
            font-size 14px
            line-height 16px
            color #E3E4E6
      .fuzzy_search
        position fixed
        width 454px
        height 200px
        overflow auto
        background #FFFFFF
        z-index 999
        .item
          margin 10px 20px
          font-size 16px
          line-height 16px
          color #061C32
          &:hover
            background #f5f6f7
            cursor pointer
        .error
          font-size 18px
          width 400px
          margin 0 auto
      .choice_list
        position relative
        width 100%
        height 540px
        overflow auto
        display flex
        flex-wrap wrap
        .language
          width 33.33%
          height 26px
          margin-top 10px
          .touch_area
            width 127px
            height 26px
            margin 6px 20px
            display flex
            text-align center
            align-items center
            &:hover
              background #F5F6F7
              cursor pointer
            .tick
              width 6px
              margin 11px 7px
              visibility hidden
            .lang_text
              font-family Gilroy
              font-size 12px
              line-height 14px
              color #061C32
.dark
  opacity 0.2
</style>
