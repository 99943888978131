/*
* 次数据主要用于不同语言的展示和选择。
*/
/* eslint-disable */
export const languages = {
  /* 分别为：[英文翻译，接口code， 当地语言] */ 
  langList:[
    ['Any language', 'auto', 'Any language'],
    ['Albanian', 'sq', 'Shqip'],
    ['Amharic', 'am', 'አማርኛ'],
    ['Arabic', 'ar', 'العربية'],
    ['Armenian', 'hy', 'Հայերեն'],
    ['Assamese', 'as', 'অসমীয়া'],
    ['Azerbaijani', 'az', 'azərbaycan dili'],
    ['Basque',  'eu', 'euskara'],
    ['Belarusian', 'be', '	беларуская мова'],
    ['Bengali', 'bn', 'বাংলা'],
    ['Bosnian', 'bs', '	bosanski jezik'],
    ['Bulgarian', 'bg', 'български език'],
    ['Burmese', 'my', 'ဗမာစာ'],
    ['Catalan', 'ca', 'català'],
    ['Cebuano', 'ceb', 'Cebuan'],
    ['Chichewa', 'ny', 'chiCheŵa'],
    ['Chinese Simplified', 'zh-CN', '中文简体'],
    ['Chinese Traditional', 'zh-TW', '中文繁体'],
    ['Corsican', 'co', 'corsu'],
    ['Croatian', 'hr', 'hrvatski jezik'],
    ['Czech', 'cs', 'čeština'],
    ['Danish', 'da', 'dansk'],
    ['Dutch', 'nl', 'Nederlands'],
    ['English', 'en', 'English'],
    ['Esperanto', 'eo', 'Esperanto'],
    ['Estonian', 'et', 'eesti'],
    ['Filipino', 'tl', 'Pilipino'],
    ['Finnish', 'fi', 'suomi'],
    ['French', 'fr', 'français'],
    ['Gaelic', 'gd', 'Gàidhlig'],
    ['Galician', 'gl', 'Galego'],
    ['Georgian', 'ka', 'ქართული'],
    ['German', 'de', 'Deutsch'],
    ['Greek', 'el', 'ελληνικά'],
    ['Gujarati', 'gu', 'ગુજરાતી'],
    ['Haitian', 'ht', '	Kreyòl ayisyen'],
    ['Hausa', 'ha', 'Harshen Hausa'],
    ['Hawaiian', 'haw', 'Hawaiʻi'],
    ['Hebrew', 'he', 'עברית'],
    ['Hindi', 'hi', 'हिन्दी'],
    ['Hmong Daw', 'hmn', '苗语'],
    ['Hungarian', 'hu', 'magyar'],
    ['Icelandic', 'is', 'Íslenska'],
    ['Igbo', 'ig', 'Asụsụ Igbo'],
    ['Indonesian', 'id', 'Bahasa Indonesia'],
    ['Irish', 'ga', 'Gaeilge'],
    ['Italian', 'it', 'Italiano'],
    ['Japanese', 'ja', '日本語'],
    ['Javanese', 'jv', 'Basa Jawa'],
    ['Kannada', 'kn', 'ಕನ್ನಡ'],
    ['Kazakh', 'kk', 'қазақ тілі'],
    ['Khmer', 'km', 'ភាសាខ្មែរ'],
    ['Klingon', 'tlh', 'tlhIngan Hol'],
    ['Korean', 'ko', '한국어'],
    ['Kurdish', 'ku', 'Kurdî'],
    ['Kyrgyz', 'ky', 'Кыргызча'],
    ['Lao', 'lo', 'ພາສາລາວ'],
    ['Latin', 'la', 'latine'],
    ['Latvian', 'lv', 'latviešu valoda'],
    ['Levantine Arabic', 'apc', 'اللَّهْجَةُ الشَّامِيَّة'],
    ['Lithuanian', 'lt', 'lietuvių kalba'],
    ['Luxembourgish', 'lb', 'Lëtzebuergesch'],
    ['Macedonian', 'mk', 'македонски јазик'],
    ['Malagasy', 'mg', 'fiteny malagasy'],
    ['Malay', 'ms', 'Bahasa Melayu'],
    ['Malayalam', 'ml', 'മലയാളം'],
    ['Maltese', 'mt', 'Malti'],
    ['Maori', 'mi', 'te reo Māori'],
    ['Marathi', 'mr', 'मराठी'],
    ['Mongolian', 'mn', 'Монгол хэл'],
    ['Nepali', 'ne', 'नेपाली'],
    ['Norwegian', 'no', 'Norsk'],
    ['Odia', 'or', 'ଓଡ଼ିଆ'],
    ['Pashto', 'ps', 'پښتو'],
    ['Persian', 'fa', 'فارسی'],
    ['Polish', 'pl', 'polski'],
    ['Portuguese', 'pt', 'Português'],
    ['Punjabi', 'pa', 'ਪੰਜਾਬੀ'],
    ['Querétaro Otomi', 'otq', 'Hñohño'],
    ['Romanian', 'ro', 'Română'],
    ['Russian', 'ru', 'русский'],
    ['Samoan', 'sm', 'Sāmoa'],
    ['Serbian-Cyrillic', 'sr', 'српски'],
    ['Serbian-Latin', 'sr-Latn', 'српски'],
    ['Shona', 'sn', 'chiShona'],
    ['Sindhi', 'sd', 'سنڌي'],
    ['Sinhala', 'si', 'සිංහල'],
    ['Slovak', 'sk', 'Slovenčina'],
    ['Slovenian', 'sl', 'Slovenščina'],
    ['Somali', 'so', 'Soomaali'],
    ['Southern Sotho', 'st', 'Sesotho'],
    ['Spanish', 'es', 'Español'],
    ['Sundanese', 'su', 'Basa Sunda'],
    ['Swahili', 'sw', 'Kiswahili'],
    ['Swedish', 'sv', 'Svenska'],
    ['Tajik', 'tg', 'тоҷикӣ'],
    ['Tamil', 'ta', 'தமிழ்'],
    ['Telugu', 'te', 'తెలుగు'],
    ['Thai', 'th', 'ไทย'],
    ['Turkish', 'tr', 'Türkçe'],
    ['Ukrainian', 'uk', 'Українська'],
    ['Urdu', 'ur', 'اردو'],
    ['Uzbek', 'uz', 'Oʻzbek'],
    ['Vietnamese', 'vi', 'Tiếng Việt'],
    ['Welsh', 'cy', 'Cymraeg'],
    ['Western Frisian', 'fy', 'Frysk'],
    ['Xhosa', 'xh', 'isiXhosa'],
    ['Yiddish', 'yi', 'ייִדיש'],
    ['Yoruba', 'yo', 'Yorùbá'],
    ['Yucatec Maya', 'yua', 'Maya'],
    ['Zulu', 'zu', 'isiZulu'],
  ]
}